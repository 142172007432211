import { Col } from 'react-bootstrap'
import './Jupiter.css'

export const Jupiter = () => {
    return (
        <Col lg={2} id='jupiterContainer' className='vh-100 d-flex flex-column'>
            <Col className='col-12 px-3 pt-5 brandDesc'>
                <h2 style={{ color: 'red' }} className='fw-bold'>Baterias Júpiter</h2>
                <p className='text-white'>Tecnologia sustentável e livre de manutenção, há 30 anos fazendo história!</p>
            </Col>
            <Col className='d-flex vc-jupiter'>
            </Col>
        </Col>
    )
}