
import { Accordion, Button, Col, Container, Row, Stack } from 'react-bootstrap';
import './App.css';
import { BuscaRef } from './components/BuscaRef';
import logoBranco from './components/img/marcas/prestocar_branco.png';

import { Moura } from './components/Marcas/Moura/Moura'
import { Jupiter } from './components/Marcas/Jupiter/Jupiter';
import { Prestocar } from './components/Marcas/Prestocar/Prestocar';
import { Freedom } from './components/Marcas/Freedom/Freedom';
import { Getpower } from './components/Marcas/Getpower/Getpower';
import { Maxforce } from './components/Marcas/Maxforce/Maxforce';

function App() {
  const openChat = () => {
    var chat = document.getElementsByClassName('nld-avatar nld-shown-slide')[0];
    return chat.click()
  }

  return (
    <div className="App">
      {/* BUSCA BATERIA */}
      <BuscaRef />
      {/* MARCAS */}
      <section id="marcas" className='bg-dark'>
        <Container fluid className=''>
          <Row>
            <Col className='d-lg-flex col-12 p-0'>
              <Moura />
              <Jupiter />
              <Prestocar />
              <Freedom />
              <Getpower />
              <Maxforce />
            </Col>
          </Row>
        </Container>
      </section>


      {/* DESTAQUES */}
      <Container className='bg-warning ' fluid style={{ padding: 0, overflowX: 'auto' }}>
        <section id="destaques">
          <Container className=''>
            <Row className='bg-warning d-flex justify-content-around align-items-center flex-nowrap text-black scroll'>
              <Col sm={12} lg={3} className='py-5 d-flex justify-content-between align-items-center'>
                <i className="far fa-thumbs-up fa-3x"></i>
                <p className='fw-medium text-start fs-5 mt-4 ps-4'>Representante Moura com os melhores preços no ATACADO e VAREJO!</p></Col>
              <Col sm={12} lg={3} className='py-5 d-flex justify-content-between align-items-center'>
                <i className="far fa-credit-card fa-3x"></i>
                <p className='fw-medium text-start fs-5 mt-4 ps-4'>Baterias originais com pagamento facilitado à vista ou à prazo!</p></Col>
              <Col sm={12} lg={3} className='py-5 d-flex justify-content-between align-items-center'>
                <i className="far fa-check-square fa-3x"></i>
                <p className='fw-medium text-start fs-5 mt-4 ps-4'><span className="fs-2 fw-bold">+40 anos</span> no mercado de baterias!</p></Col>
            </Row>
          </Container>
        </section>
      </Container>

      {/* ORÇAMENTO */}
      <Container className='' fluid style={{ padding: 0, backgroundColor: '#1220A8' }}>
        <section id='orcamento'>
          <Container className='d-flex flex-column justify-content-center py-5'>
            <h3 className='text-white mx-auto pb-4'>Baterias no atacado</h3>
            <Row>
              <Col xs={12} lg={12} className='d-flex-column d-lg-flex justify-content-around flex-wrap'>
                {/* CARD */}
                <Col xs={12} lg={3} className='bg-light p-4 mt-2 rounded'>
                  <h5>Lojista Revendedor</h5>
                  <p className='text-muted'>Encontre os melhores preços para revenda, peça um orçamento online agora mesmo!</p>
                </Col>
                <Col xs={12} lg={3} className='bg-light p-4 mt-2 rounded'>
                  <h5>Frota empresarial</h5>
                  <p className='text-muted'>Economia em frota de veículos é possível!</p>
                </Col>
                <Col xs={12} lg={3} className='bg-light p-4 mt-2 rounded'>
                  <h5>Maquinário</h5>
                  <p className='text-muted'>Se a sua empresa possui equipamentos que não podem parar, a Prestocar é a solução!</p>
                </Col>
                <Col lg={9} className='text-center'>
                  <Button variant='warning' className='mx-auto mt-5' onClick={openChat}>Orçamento <i className="ms-1 fa-regular fa-comment"></i></Button>
                </Col>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>

      {/* PROMOÇÕES */}
      {/* <Container className='vh-100 bg-primary d-flex flex-column justify-content-center ' fluid style={{ padding: 0 }}>
        <section>
          <Container>
            <h3 className='mb-4 text-white'>Últimas promoções</h3>
            <Row>
              <Col xs={12} lg={6}>

                <Col className='d-flex px-3 py-2 bg-light rounded-4 mb-2'>
                  <Col>
                    <img src={require('./components/img/marcas/bateria-moura.webp')} width='100%' alt='?' />
                  </Col>
                  <Col>
                    <p className='fw-bold text-muted'>M60GD - 60 Ah</p>
                    <p>3x de R$ 139,66 ou R$ 399,00 à vista</p>
                  </Col>
                </Col>
                <Col className='d-flex px-3 py-2 bg-light rounded-4 mb-2'>
                  <Col>
                    <img src={require('./components/img/marcas/bateria-moura.webp')} width='100%' alt='?' />
                  </Col>
                  <Col>
                    <p className='fw-bold text-muted'>M60GD - 60 Ah</p>
                    <p>3x de R$ 139,66 ou R$ 399,00 à vista</p>
                  </Col>
                </Col>
                <Col className='d-flex px-3 py-2 bg-light rounded-4 mb-2'>
                  <Col>
                    <img src={require('./components/img/marcas/bateria-moura.webp')} width='100%' alt='?' />
                  </Col>
                  <Col>
                    <p className='fw-bold text-muted'>M60GD - 60 Ah</p>
                    <p>3x de R$ 139,66 ou R$ 399,00 à vista</p>
                  </Col>
                </Col>

              </Col>
            </Row>
          </Container>
        </section>
      </Container> */}

      {/* FAQ */}
      <Container className='vh-100 d-flex flex-column justify-content-center' fluid style={{ padding: 0 }}>
        <section id='faq'>
          <Container>
            <Col className='d-flex flex-column justify-content-between'>
              <h4 className='mb-4'>Perguntas frequentes</h4>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Como posso fazer um pedido?</Accordion.Header>
                  <Accordion.Body>
                    <strong>Varejo:</strong> Dirija-se a uma de nossas <a href="#lojas" className="text-decoration-none">lojas</a>, caso tenha alguma dúvida, entre em contato no nosso WhatsApp!
                    <hr />
                    <strong>Atacado:</strong> Inicie o atendimento pelo Chat, fale um pouco sobre sua demanda e retornaremos com o orçamento. Com o pedido aprovado, entregamos sem custo!
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>As baterias Prestocar tem garantia?</Accordion.Header>
                  <Accordion.Body>
                    Sim, temos modelos com 12 e 24 meses de garantia sem valor adicional. Consulte os modelos!
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Quais são as formas de pagamento?</Accordion.Header>
                  <Accordion.Body>
                    No momento aceitamos <strong>pix, cartão de crédito e débito</strong>.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>O que significa "valor à base de troca"?</Accordion.Header>
                  <Accordion.Body>
                    Isso significa que você precisa entregar a sua bateria usada ou com defeito (sucata) <strong>de mesma amperagem</strong> no momento da compra para que o valor se mantenha o mesmo. Caso não tenha, uma taxa é adicionada ao valor total da bateria!
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Vocês vendem no atacado?</Accordion.Header>
                  <Accordion.Body>
                    Sim, para lojistas ou empresas que demandam baterias em maior quantidade, como empresas de logísticas que possuem frota de veículos e empilhadeiras. O FRETE para essa modalidade é gratuito!
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>

          </Container>
        </section>
      </Container>

      <footer className='bg-dark text-white pt-5'>
        <Container>
          <Row className='d-flex flex-column'>
            <Col className="text-center">
              <img src={logoBranco} alt='' />
            </Col>
            <Col className='d-md-flex justify-content-between'>
              <Col lg={3} className='mb-3'>
                <h6>Mapa do site</h6>
                <Stack>
                  <a href='#hero' className='py-1 text-decoration-none text-light'>Início</a>
                  <a href='#marcas' className='py-1 text-decoration-none text-light'>Marcas</a>
                  <a href='#buscaBateria' className='py-1 text-decoration-none text-light'>Delivery</a>
                  <a href='#orcamento' className='py-1 text-decoration-none text-light'>Atacado</a>
                  <a href='#faq' className='py-1 text-decoration-none text-light'>Dúvidas</a>
                </Stack>
              </Col>

              <Col lg={3} id='lojas'>
                <Col className='mb-4'>
                  <p>
                    <span className='fw-bold'>Prestocar Mooca</span><br />
                    Rua Piratininga 1049, Mooca, São Paulo - SP
                  </p>
                </Col>
                <Col>
                  <p>
                    <span className='fw-bold'>Prestocar Brás</span><br />
                    Av. Alcântara Machado (Radial Leste) 980, Brás, São Paulo - SP
                  </p>
                </Col>
              </Col>
              <Col lg={3}>
                <Col className='mb-4'>
                  <p>
                    <span className='fw-bold'>Baterias Prestocar LTDA.</span><br />
                    CNPJ: 07.340.336/0001-42
                  </p></Col>
                <Col>
                  <p>
                    <span className='fw-bold'>Copyright © 2024</span><br />
                    Todos os direitos reservados.
                  </p>
                </Col>
              </Col>
            </Col>
            <Col className='d-inline-flex justify-content-center text-white mt-5 mb-2'>
              <a href="tel:1133994240" className='text-decoration-none text-white d-flex align-self-baseline mx-lg-2'><i className="fa fa-phone fa-2x px-2" aria-hidden="true"></i>{'11 3399-4240'}</a>
              <a href="https://wa.me/5511940321313" className='text-decoration-none text-white mx-lg-2'><i className="fa-brands fa-whatsapp fa-2x px-2" aria-hidden="true"></i>{' '}</a>
              <a href="https://www.instagram.com/bateriasprestocar" className='text-decoration-none text-white mx-lg-2'><i className="fa-brands fa-2x fa-instagram px-2" aria-hidden="true"></i>{''}</a>
              <a href="https://www.facebook.com/bateriasprestocar" className='text-decoration-none text-white mx-lg-2'><i className="fa-brands fa-2x fa-facebook px-2" aria-hidden="true"></i>{''}</a>
              <a href='mailto:prestocarvendas@gmail.com'><i className="fa fa-envelope fa-2x text-white px-2" aria-hidden="true"></i>{''}</a>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
