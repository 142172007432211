import { Col } from 'react-bootstrap'
import './Freedom.css'

export const Freedom = () => {
    return (
        <Col lg={2} id='freedomContainer' className='vh-100 d-flex flex-column'>
            <Col className='col-12 px-3 pt-5 brandDesc'>
                <h2 style={{ color: 'red' }} className='fw-bold'>Baterias Estacionárias Freedom</h2>
                <p className='text-white'>Ótima escolha para nobreaks, painéis solares e energia eólica.</p>
            </Col>
            <Col className='d-flex vc-freedom'>
            </Col>
        </Col>
    )
}