import { Col } from 'react-bootstrap'
import './Getpower.css'

export const Getpower = () => {
    return (
        <Col lg={2} id='getpowerContainer' className='vh-100 d-flex flex-column'>
            <Col className='col-12 px-3 pt-5 brandDesc'>
                <h2 style={{ color: 'orange' }} className='fw-bold'>Baterias Estacionárias GetPower</h2>
                <p className='text-white'>Baterias para data centers e painéis solares.</p>
            </Col>
            <Col className='d-flex vc-getpower'>
            </Col>
        </Col>
    )
}