import { Col } from 'react-bootstrap'
import './Maxforce.css'

export const Maxforce = () => {
    return (
        <Col lg={2} id='maxContainer' className='vh-100 d-flex flex-column'>
            <Col className='col-12 px-3 pt-5 brandDesc'>
                <h2 style={{ color: 'yellow' }} className='fw-bold'>Baterias Tracionárias e Solares</h2>
                <p className='text-white'>Tecnologia inovadora para veiculos de tração e painéis solares!</p>
            </Col>
            <Col className='d-flex vc-max'>
            </Col>
        </Col>
    )
}