import axios from 'axios';
import { useState } from 'react';
import { Badge, Button, Col, Container, Form, FormControl, FormLabel, Modal, Row, Stack } from 'react-bootstrap'

export const BuscaRef = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [manualSearch, setManualSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResult, setSelectedResult] = useState(null);
    const [ah, setAh] = useState('00');

    const [show, setShow] = useState(false);
    const [cep, setCep] = useState("");
    const [email, setEmail] = useState("");

    const handleClose = () => {
        setShow(false)
        setSelectedResult(null);
        setCep('');
    };
    const handleShow = () => setShow(true);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        fetchSearchResults();
    };

    

    const fetchSearchResults = async () => {
        try {
            // http://localhost:3001
            const response = await axios.get('https://www.bateriasprestocar.com.br/search.php', {
                params: {
                    q: searchTerm
                }
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error('Error fetching search results', error);
        }
    };


    const handleDelivery = async () => {
        const userParams = {
            email: email,
            postal_code: cep

        }
        window['gtag_report_conversion'](undefined, userParams)
        try {
            // http://localhost:3001
            const response = await axios.post('https://www.bateriasprestocar.com.br/delivery.php', {
                data: new Date().toISOString().slice(0, 10),
                modelo: searchResults.referencia || manualSearch,
                cep: cep,
                email: email
            });

            if (response.data.success) {
                handleClose();
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error sending delivery data', error);
        }
    };

    const handleResultClick = (result) => {
        handleSelectResult(result);
    };

    const handleSelectResult = (result) => {
        var ref = result.referencia;
        let ah = ref.replace(/[^0-9]/g, '');
        setAh(ah)
        setSelectedResult(result);
    };


    return (
        <Container id='bgBusca' fluid className='bg-primary' style={{ padding: 0 }}>
            <section id="buscaBateria">
                <Container className='d-flex flex-column justify-content-start align-items-center py-5'>
                    <h3 className='mb-4 text-white'>Encontre a bateria ideal para o seu veículo!</h3>
                    <Col className='d-flex flex-column justify-content-around flex-wrap col-12'>
                        <Col xs={12} lg={6} className=''>
                            <article>
                                <Form id='formDelivery' onSubmit={handleSearchSubmit}>
                                    <Form.Label className='text-white'>Digite o modelo</Form.Label>
                                    <Row>
                                        <span className="text-white">* Tabela atualizada até 2018</span>
                                    </Row>
                                    <Row>
                                        <Col xs={9} lg={8}>
                                            <Form.Control className='py-2 fs-5' type='text' value={searchTerm} placeholder='Exemplo: ONIX, PALIO...' onChange={(e) => setSearchTerm(e.target.value)}></Form.Control>
                                        </Col>
                                        <Col xs={3} lg={4}>
                                            <Button variant="warning" type="submit">
                                                <i className="fa-solid fa-magnifying-glass ms-1"></i>
                                            </Button>
                                        </Col>
                                        <Col xs={10} lg={12} className='bg-light rounded mt-3 mx-auto' style={{ minHeight: '15vh', maxHeight: '20vh', overflow: 'scroll' }}>
                                            <Stack id='selectModel' gap={searchResults.length} className="">
                                                {searchResults.map((result, index) => (
                                                    <div key={index} onClick={() => handleResultClick(result)}>
                                                        <p>{result.veiculo} - {result.ano_inicio} a {result.ano_fim}</p>
                                                    </div>
                                                ))}
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={10} className='mt-2'>
                                            <FormLabel className='text-white fs-5 fw-bold'>Não encontrou?</FormLabel>
                                            <FormControl placeholder='Digite o modelo da bateria ou veículo' type='text' onChange={(e) => setManualSearch(e.target.value)} value={manualSearch}></FormControl>
                                        </Col>
                                        <Col xs={2} className='d-flex flex-colum align-items-end'>
                                            <Button variant="warning" onClick={handleShow} >
                                                <i className="fa-solid fa-truck"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </article>
                        </Col>
                        <Col lg={6}>
                            {selectedResult && (

                                <article id="resultadoBateria" className='mt-4'>
                                    <span className='text-white'>Bateria recomendada</span>
                                    <Container>
                                        <Row className=''>
                                            <Col xs={12} md={4} lg={12} id="box" className='d-flex flex-lg-column align-items-center flex-wrap bg-light rounded py-3'>
                                                <Col xs={9} lg={10} className='text-primary'>
                                                    <Col><span className='fw-bold fs-4'>{selectedResult.referencia}</span></Col>
                                                    <Col><span className='text-muted fw-semibold'>{selectedResult.marca + ' ' + selectedResult.veiculo} - {selectedResult.ano_inicio} a {selectedResult.ano_fim}</span></Col>
                                                </Col>

                                                <Col xs={3} lg={2} className='d-flex justify-content-center align-items-center text-primary'>
                                                    <Badge className='p-2'>{ah} Ah</Badge>
                                                </Col>

                                                <Col className='text-center'>
                                                    <img src={require('./img/marcas/bateria-moura.webp')} width={240} alt='' />
                                                </Col>
                                                <Button variant='success mx-auto w-100' onClick={handleShow}>Entrega <i className="ms-1 fa-solid fa-truck"></i></Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </article>
                            )}

                        </Col>
                    </Col>
                    <Modal id="modalPagamento" show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Informações para entrega</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form id='formDelivery_data'>
                                <Col>
                                    <Form.Label>Digite o CEP</Form.Label>
                                    <Form.Control value={cep} type='text' placeholder='Somente números' onChange={(e) => setCep(e.target.value)} maxLength={10} required></Form.Control>
                                    <Form.Label>E-mail para contato</Form.Label>
                                    <Form.Control value={email} type='email' placeholder='joao@email.com' onChange={(e) => setEmail(e.target.value)} required></Form.Control>
                                </Col>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="primary" id="btnPagamento" onClick={handleDelivery} type='submit'>
                                Pagamento
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </section>
        </Container>
    )
}