import { Col } from 'react-bootstrap'
import './Prestocar.css'
import carroPrestocar from './carroPrestocar.webp'
import caminhaoPrestocar from './caminhaoPrestocar.webp'

export const Prestocar = () => {
    return (
        <Col lg={2} id='prestocarContainer' className='vh-100 d-flex flex-column'>
            <Col className='col-12 px-3 pt-5 brandDesc'>
                <h2 style={{ color: 'red' }} className='fw-bold'>Baterias Prestocar</h2>
                <p className='text-white'>Linha econômica premium de baterias automotivas, direto da fábrica para carros e caminhões.</p>
                <p className='text-white'>Há 40 anos sendo o melhor custo-benefício do mercado!</p>
            </Col>
            <Col className='d-flex flex-colum vc-prestocar'>
                <img src={caminhaoPrestocar} className='p-veiculo caminhaoPrestocar' alt='Caminhao' />
                <img src={carroPrestocar} className='p-veiculo carroPrestocar' alt='Carro' />
            </Col>
        </Col>
    )
}