import { Col } from 'react-bootstrap'
import carroAzul from './carroAzul.webp'
import motoAmarela from './motoAmarela.webp'
import './Moura.css'

export const Moura = () => {
    return (
        <Col lg={2} id='mouraContainer' className='vh-100 d-flex flex-column justify-content-center'>
            <Col className='col-12 ps-3 pt-5 brandDesc'>
                <h2 style={{ color: '#FFBF3C' }} className='fw-bold'>Baterias Moura</h2>
                <p className='text-white'>Representante Moura com preços <span className='fw-bold'>campeões</span> no <span className='fw-bold'>varejo e atacado!</span></p>
                <p className='text-white'> Baterias para carro e moto.</p>
            </Col>
            <Col className='d-flex vc-moura'>
                <img src={motoAmarela} className='m-veiculo motoMoura' alt='Moto Amarela' />
                <img src={carroAzul} className='m-veiculo carroMoura' alt='Carro Azul' />
            </Col>
        </Col>
    )
}